import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { UserGroups } from '../../Auth';

import { withAuthContext, getUserGroupPrettyName } from '../../Auth';
import { withTranslation } from 'react-i18next';
import ConfirmPositionsTable from './ConfirmPositionsTable';

import "../../scss/useradmin/_invite_user.scss";


class InternalUserConfirm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            success: false,
        }
    }


    handleSubmit = (e) => {

        this.setState({ submitted: true }, () => {
            this.props.submitData().then(() => {
                this.setState({ submitted: false, success: true });
            });
        });

    }

    render() {
        let { t, users, permissionGroup } = this.props;
        let { submitted, success } = this.state;

        let disabled = (submitted || success || !users) ? true : false;

        return (
            <div className="InternalUserConfirm">
                <div className="MyProfile-header">

                    <div className="card">
                        <div className="card-header d-flex justify-content-between">
                            <p>{t("CONFIRM & SAVE")}</p><p>{t("Step 5 of 5")}</p>
                        </div>
                        <div className="card-body">
                            <div className="tables mx-5 my-4 d-flex justify-content-center">
                                <div className="card mr-4 w-75">
                                    <div className="card-header d-flex align-items-center justify-content-between">
                                        <span>{t("Users")}</span>
                                    </div>
                                    <div>
                                        <table className="table table-bordered table-striped">
                                            <thead>
                                                <td className="big-td"> <div className={"font-weight-bold"}>{t("Name")}</div></td>
                                                <td className="big-td"> <div className={"font-weight-bold"}>{t("Email")}</div></td>
                                                <td className="big-td"> <div className={"font-weight-bold"}>{t("Company Name")}</div></td>
                                                <td className="big-td"> <div className={"font-weight-bold"}>{t("Job Title")}</div></td>
                                                <td className="big-td"> <div className={"font-weight-bold"}>{t("Permission")}</div></td>
                                            </thead>
                                            <tbody>
                                                {users.map((user, index) => {
                                                    let rows = [];

                                                    rows.push(
                                                        <tr>
                                                            <td className="text-left">{user.firstName} {user.lastName}</td>
                                                            <td className="text-left">{user.email}</td>
                                                            <td className="text-left">{user.companyName}</td>
                                                            <td className="text-left">{user.jobTitle}</td>
                                                            <td className="text-left">{getUserGroupPrettyName(user.group)}</td>
                                                        </tr>
                                                    );
                                                    return rows;
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="card w-25">
                                    <div className="card-header d-flex align-items-center justify-content-between">
                                        <span>{t('Client')}</span>
                                    </div>
                                    <div className="scrollable">
                                         {permissionGroup === UserGroups.InternalReinsuranceTeam ? <div className="px-3 py-2">All Clients</div> : <ConfirmPositionsTable positions={this.props.positions}></ConfirmPositionsTable>}       
                                        
                                    </div>
                                </div>

                            </div>

                            <div className="d-flex justify-content-between">
                                <Link to={permissionGroup === UserGroups.InternalReinsuranceTeam ? "/users/add/assurant/permissions" : "/users/add/assurant/positions"}><i className="cil-arrow-left mr-2"></i>{t("Back")}</Link>
                                <Button color="primary"
                                    onClick={this.handleSubmit}
                                    disabled={disabled}
                                >
                                    {submitted ? t("Saving...") : t("Confirm & Save")}
                                </Button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(withAuthContext(InternalUserConfirm));
