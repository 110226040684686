import React from "react";
import { useTranslation } from "react-i18next";
import { useReports } from "../Reports";
import { useHistory } from "react-router-dom";

function HomePage() {
  const { t } = useTranslation();

  const reports = useReports();

  const history = useHistory();

  function getFirstReportTarget(report) {
    const reportKeys = Object.keys(report);
    const isLeaf = reportKeys.includes("id");
    const reportIsNotEmpty = reportKeys.length > 0;
    const reportIsObject = typeof report === "object" && report !== null;

    if (reportIsNotEmpty && reportIsObject) {
      if (isLeaf) {
        return report.target;
      } else {
        return getFirstReportTarget(report[reportKeys[0]]);
      }
    }

    return null;
  }

  let target = getFirstReportTarget(reports);
  if (target !== null) {
    history.push(target);
  }

  return (
    <div className="App-header text-center">
      <img alt={t("No Data Available")} src="../images/no-data.png"></img>
      <p className="text-muted text-uppercase pt-3">{t("No data")}</p>
    </div>
  );
}

export default HomePage;
