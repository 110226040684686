import React from 'react';
import '../../scss/components/_notifications_menu.scss';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import classNames from "classnames";


function NotificationsMenu(props) {
  const { t } = useTranslation();
  
  const { location, isOpen, toggle,excludeLocations } = props;
  let isActivate = excludeLocations.some(function (loc) { return location.pathname.startsWith(loc); });

    return (
        !(excludeLocations.includes(location.pathname) || isActivate) ?

            // TODO: connect to wherever notifications will be coming from.

            <div className="d-flex justify-content-center flex-nowrap">
                <div className={classNames("notifications-menu-mask", { "is-open": isOpen })} onClick={toggle}></div>
                <div className={classNames("card", "notifications-menu-container", "shadow", { "is-open": isOpen })}>
                    <div className="card-header d-flex flex-nowrap justify-content-between">
                        <div><i className="cil-bell"></i> {t('Notifications')}</div>
                        <a href="/" className="text-link">Delete All</a>
                    </div>
                    <div className="card-body p-0 pb-3">
                        <div className="notification-content d-flex align-items-center justify-content-between flex-nowrap px-3 py-2">
                            <div className="">
                                <div>Monthly Data Update</div>
                                <div className="notification-bottom-text pt-2">John Galt &bull; June 20, 2020</div>
                            </div>
                            <div>
                                <i className="cil-trash"></i>
                            </div>
                        </div>
                        <div className="notification-content d-flex align-items-center justify-content-between flex-nowrap px-3 py-2">
                            <div className="">
                                <div>Monthly Data Update</div>
                                <div className="notification-bottom-text pt-2">John Galt &bull; June 20, 2020</div>
                            </div>
                            <div>
                                <i className="cil-trash"></i>
                            </div>
                        </div>
                        <div className="notification-content d-flex align-items-center justify-content-between flex-nowrap px-3 py-2">
                            <div className="">
                                <div>Monthly Data Update</div>
                                <div className="notification-bottom-text pt-2">John Galt &bull; June 20, 2020</div>
                            </div>
                            <div>
                                <i className="cil-trash"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            : ''
    );
}


export default withRouter(NotificationsMenu);
