import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import config from '../../config';
import { withAuthContext, UserGroups } from '../../Auth';

import SearchUsers from './SearchUsers';
import InternalUserPermissions from './InternalUserPermissions';
import InternalUserConfirm from './InternalUserConfirm';
import SelectClientPositions from './SelectClientPositions'
import AlertBox from '../general/AlertBox';
import { withTranslation } from 'react-i18next';

class InternalUserInvitationSteps extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            positions: [],
            status: null,
            permissionGroup: UserGroups.InternalReporting,
            renderAlertBox: false,
            submitted: false,
        };
    }

    onAddUsers = (usersToAdd) => {
        let { users } = this.state;

        // initialize with group
        for (const user of usersToAdd) {
            user.group = this.state.permissionGroup.oktaName;
        }

        let allUsers = [...users, ...usersToAdd];

        this.setState({ users: allUsers });
    }

    onRemoveUsers = (usersToRemove) => {
        let { users } = this.state;
        for (const user of usersToRemove) {
            users.splice(users.indexOf(user), 1);
        }
        this.setState({ users });
    }

    onSetPermission = (permissionGroup) => {
        let { users } = this.state;
        let usersWithPermission = [];

        usersWithPermission = users.map((user => {
            user.group = permissionGroup.oktaName;
            return user;
        }));

        this.setState({ users: usersWithPermission, permissionGroup });
    }

    onSetPositions = (selectedPositions) => {
        this.setState({ positions: [...selectedPositions] });
    }


    closeAlertBox = () => {
        if (this.state.submitStatus) {
            this.props.history.push('/users');
        } 
        this.setState( {renderAlertBox: false});
    }

    async submitData(authContext) {
        let { users, positions } = this.state;

        let response = await fetch(`${config.apiUrl}users`, {
            method: 'post',
            headers: new Headers({
                'Authorization': 'Bearer ' + await authContext.getAccessToken(),
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
                users: users,
                positions: positions.map(p => p.id)
            })
        });

        if (response.ok) {
            // Success! 
            this.setState({ users: [], submitStatus: true, renderAlertBox: true, submitted: true });
        } else {
            // Failed
            this.setState({ submitStatus: false, renderAlertBox: true, submitted: true });
        }
    }

    render() {
        let { users, permissionGroup, positions } = this.state;
        let { authContext, t } = this.props;
        return (
            <>
                <Route exact path="/users/add/assurant">
                    <SearchUsers selectedUsers={users} addUsers={this.onAddUsers} removeUsers={this.onRemoveUsers} />
                </Route>
                <Route exact path="/users/add/assurant/permissions" >
                    <InternalUserPermissions permissionGroup={permissionGroup} setPermission={(permissionGroup) => this.onSetPermission(permissionGroup)} />
                </Route>
                <Route exact path="/users/add/assurant/positions">
                    <SelectClientPositions selectedPositions={positions} setPositions={this.onSetPositions} previousStep="/users/add/assurant/permissions" nextStep="/users/add/assurant/confirm" />
                </Route>

                <Route exact path="/users/add/assurant/confirm">
                    <InternalUserConfirm
                        submitStatus={this.state.submitStatus}
                        users={users}
                        permissionGroup={permissionGroup}
                        positions={positions}
                        submitData={() => this.submitData(authContext)}
                    />
                </Route>

                <AlertBox
                    title={this.state.submitStatus ? t('Invite Sent') : t('Invite Not Sent')}
                    text={this.state.submitStatus ? t('An email has been sent to each user.') : t('Something went wrong!')}
                    onClose={this.closeAlertBox}
                    isOpen={this.state.renderAlertBox}
                    isSuccess={this.state.submitStatus}>
                </AlertBox>
            </>
        );
    }
}

export default withTranslation()(withRouter(withAuthContext(InternalUserInvitationSteps)));
