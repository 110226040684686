import React, { Component } from "react";
import OktaSignIn from "@okta/okta-signin-widget";
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import config from "../../config";
import { withTranslation } from "react-i18next";
import { withOktaAuth } from "@okta/okta-react";

class OktaSignInWidget extends Component {
  constructor(props) {
    super(props);
    this.signInWidget = React.createRef();
  }

  async componentDidMount() {
    const { oktaAuth } = this.props;
    console.log(oktaAuth, "oktaauth");
    try {
      //const session = await oktaAuth.session.get();
      try {
        const tokens = await oktaAuth.token.getWithoutPrompt({
          responseType: ["code"],
          //sessionToken: session.sessionToken,
          scopes: config.oktaScopes,
        });
        await this.props.onSuccess({
          status: "SUCCESS",
          ...tokens,
        });
      } catch (err) {
        console.log(err);
        this.props.onError();
        this.showWidget();
      }

      /*if (session.status === "ACTIVE") {
        try {
          const tokens = await oktaAuth.token.getWithoutPrompt({
            responseType: ["code"],
            sessionToken: session.sessionToken,
            scopes: config.oktaScopes,
          });
          await this.props.onSuccess({
            status: "SUCCESS",
            ...tokens,
          });
        } catch (err) {
          console.log(err);
          this.props.onError();
          this.showWidget();
        }
      } else {
        console.log("No active session found");
        this.props.onError();
        this.showWidget();
      }*/
    } catch (err) {
      console.log(err);
      //User is not logged in. show the sign in widget
      this.props.onError();
      this.showWidget();
    }
  }

  showWidget() {
    const loginPrefix = "GA__";
    const { t } = this.props;
    this.widget = new OktaSignIn({
      /**
       * Note: when using the Sign-In Widget for an OIDC flow, it still
       * needs to be configured with the base URL for your Okta Org. Here
       * we derive it from the given issuer for convenience.
       */
      baseUrl: config.oktaBaseUri,
      clientId: config.oktaClientId,
      redirectUri: config.oktaSigninRedirectUrl,
      useInteractionCodeFlow: false,
      useClassicEngine: true,

      logo: "../images/assurant-logo.png",
      i18n: {
        en: {
          "primaryauth.title": t("Digital Hub Reinsurance"),
          "password.forgot.email.or.username.placeholder": t("Email Address"),
          "password.forgot.email.or.username.tooltip": t("Email Address"),
          "password.forgot.emailSent.desc": t(
            "Email has been sent to {0} with password reset instructions."
          ),
          "primaryauth.username.placeholder": t("Email Address or User ID"),
          "primaryauth.username.tooltip": t("Email Address or User ID"),
          help: t("Need support?"),
        },
      },
      colors: {
        brand: "#0070B9",
      },
      features: {
        passwordlessAuth: true,
      },

      authParams: {
        issuer: config.oktaIssuerUrl,
        display: "page",
        responseMode: "fragment",
        responseType: ["code"],
        scopes: config.oktaScopes,
      },
      transformUsername: function (username, operation) {
        return username.indexOf(loginPrefix) > -1 || !username.includes("@")
          ? username
          : loginPrefix + username;
      },
      helpLinks: {
        custom: [
          {
            text: "Need support?",
            href: "/login-support",
          },
        ],
      },
    });
    this.widget.renderEl(
      { el: this.signInWidget.current },
      this.props.onSuccess,
      this.props.onError
    );

    this.widget.on("ready", (context) => {
      if (context.controller === "primary-auth") {
        const userNameElement = document.getElementById("okta-signin-username");
        if (
          userNameElement &&
          userNameElement.value &&
          userNameElement.value.startsWith(loginPrefix)
        ) {
          userNameElement.value = userNameElement.value.slice(
            loginPrefix.length
          );
        }
      }
    });
  }

  componentWillUnmount() {
    if (this.widget) {
      this.widget.remove();
    }
  }

  render() {
    return <div ref={this.signInWidget} id="sign-in-widget" />;
  }
}

export default withOktaAuth(withTranslation()(OktaSignInWidget));
