import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { UserGroups } from '../../Auth';
import "../../scss/useradmin/_invite_user.scss";


class InternalUserPermissions extends Component {

    selectPermission = (plan) => {
        this.props.setPermission(plan);
    }

    render() {
        let { t, permissionGroup, headerText } = this.props;

        return (
            <div className="InternalUserPermissions userPermissions">
                <div className="MyProfile-header">

                    <div className="card">
                        <div className="card-header d-flex justify-content-between">
                            <span>{headerText ? headerText : t("Select Permission Level")}</span><span>{headerText ? "" : t("Step 3 of 5")}</span>
                        </div>
                        <div className="card-body">

                            <div className="mx-3 px-5">
                                <table className="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <td></td>
                                            <td onClick={() => this.selectPermission(UserGroups.InternalReinsuranceTeam)} className={permissionGroup === UserGroups.InternalReinsuranceTeam ? "selected first" : ''}> <div className={"d-flex flex-column align-items-center justify-content-center"}><input type="radio" name="plan_name" checked={permissionGroup === UserGroups.InternalReinsuranceTeam}></input><p>{t("Internal Reinsurance Team")}</p></div></td>
                                            <td onClick={() => this.selectPermission(UserGroups.InternalExecutive)} className={permissionGroup === UserGroups.InternalExecutive ? "selected first" : ''}> <div className="d-flex flex-column align-items-center justify-content-center"><input type="radio" name="plan_name" checked={permissionGroup === UserGroups.InternalExecutive}></input><p>{t("Executive")}</p></div></td>
                                            <td onClick={() => this.selectPermission(UserGroups.InternalClientRep)} className={permissionGroup === UserGroups.InternalClientRep ? "selected first" : ''}> <div className="d-flex flex-column align-items-center justify-content-center"><input type="radio" name="plan_name" checked={permissionGroup === UserGroups.InternalClientRep}></input><p>{t("Client Rep")}</p></div></td>
                                            <td onClick={() => this.selectPermission(UserGroups.InternalFinanceAIZ)} className={permissionGroup === UserGroups.InternalFinanceAIZ ? "selected first" : ''}> <div className="d-flex flex-column align-items-center justify-content-center"><input type="radio" name="plan_name" checked={permissionGroup === UserGroups.InternalFinanceAIZ}></input><p>{t("Finance AIZ")}</p></div></td>
                                            <td onClick={() => this.selectPermission(UserGroups.InternalReporting)} className={permissionGroup === UserGroups.InternalReporting ? "selected first" : ''}> <div className="d-flex flex-column align-items-center justify-content-center"><input type="radio" name="plan_name" checked={permissionGroup === UserGroups.InternalReporting}></input><p>{t("Cessions")}</p></div></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="text-left font-weight-bold">{t("Manage Users Set-Up")}</td>
                                            <td className={permissionGroup === UserGroups.InternalReinsuranceTeam ? "selected" : ''}><i className="cil-check-alt"></i></td>
                                            <td className={permissionGroup === UserGroups.InternalExecutive ? "selected" : ''}></td>
                                            <td className={permissionGroup === UserGroups.InternalClientRep ? "selected" : ''}></td>
                                            <td className={permissionGroup === UserGroups.InternalFinanceAIZ ? "selected" : ''}></td>
                                            <td className={permissionGroup === UserGroups.InternalReporting ? "selected" : ''}></td>
                                        </tr>
                                        <tr>
                                            <td className="text-left font-weight-bold">{t("Manage Clients/Positions")}</td>
                                            <td className={permissionGroup === UserGroups.InternalReinsuranceTeam ? "selected" : ''}><i className="cil-check-alt"></i></td>
                                            <td className={permissionGroup === UserGroups.InternalExecutive ? "selected" : ''}></td>
                                            <td className={permissionGroup === UserGroups.InternalClientRep ? "selected" : ''}></td>
                                            <td className={permissionGroup === UserGroups.InternalFinanceAIZ ? "selected" : ''}></td>
                                            <td className={permissionGroup === UserGroups.InternalReporting ? "selected" : ''}></td>
                                        </tr>
                                        <tr>
                                            <td className="text-left font-weight-bold">{t("Summary")}</td>
                                            <td className={permissionGroup === UserGroups.InternalReinsuranceTeam ? "selected" : ''}><i className="cil-check-alt"></i></td>
                                            <td className={permissionGroup === UserGroups.InternalExecutive ? "selected" : ''}><i className="cil-check-alt"></i></td>
                                            <td className={permissionGroup === UserGroups.InternalClientRep ? "selected" : ''}><i className="cil-check-alt"></i></td>
                                            <td className={permissionGroup === UserGroups.InternalFinanceAIZ ? "selected" : ''}></td>
                                            <td className={permissionGroup === UserGroups.InternalReporting ? "selected" : ''}></td>
                                        </tr>
                                        <tr>
                                            <td className="text-left font-weight-bold">{t("Profit Development")}</td>
                                            <td className={permissionGroup === UserGroups.InternalReinsuranceTeam ? "selected" : ''}><i className="cil-check-alt"></i></td>
                                            <td className={permissionGroup === UserGroups.InternalExecutive ? "selected" : ''}><i className="cil-check-alt"></i></td>
                                            <td className={permissionGroup === UserGroups.InternalClientRep ? "selected" : ''}><i className="cil-check-alt"></i></td>
                                            <td className={permissionGroup === UserGroups.InternalFinanceAIZ ? "selected" : ''}></td>
                                            <td className={permissionGroup === UserGroups.InternalReporting ? "selected" : ''}></td>
                                        </tr>
                                        <tr>
                                            <td className="text-left font-weight-bold">{t("Franchise Loss Ratios")}</td>
                                            <td className={permissionGroup === UserGroups.InternalReinsuranceTeam ? "selected" : ''}><i className="cil-check-alt"></i></td>
                                            <td className={permissionGroup === UserGroups.InternalExecutive ? "selected" : ''}><i className="cil-check-alt"></i></td>
                                            <td className={permissionGroup === UserGroups.InternalClientRep ? "selected" : ''}><i className="cil-check-alt"></i></td>
                                            <td className={permissionGroup === UserGroups.InternalFinanceAIZ ? "selected" : ''}></td>
                                            <td className={permissionGroup === UserGroups.InternalReporting ? "selected" : ''}></td>
                                        </tr>
                                        <tr>
                                            <td className="text-left font-weight-bold">{t("Claims Trends")}</td>
                                            <td className={permissionGroup === UserGroups.InternalReinsuranceTeam ? "selected" : ''}><i className="cil-check-alt"></i></td>
                                            <td className={permissionGroup === UserGroups.InternalExecutive ? "selected" : ''}><i className="cil-check-alt"></i></td>
                                            <td className={permissionGroup === UserGroups.InternalClientRep ? "selected" : ''}><i className="cil-check-alt"></i></td>
                                            <td className={permissionGroup === UserGroups.InternalFinanceAIZ ? "selected" : ''}></td>
                                            <td className={permissionGroup === UserGroups.InternalReporting ? "selected" : ''}></td>
                                        </tr>
                                        <tr>
                                            <td className="text-left font-weight-bold">{t("New Business")}</td>
                                            <td className={permissionGroup === UserGroups.InternalReinsuranceTeam ? "selected" : ''}><i className="cil-check-alt"></i></td>
                                            <td className={permissionGroup === UserGroups.InternalExecutive ? "selected" : ''}><i className="cil-check-alt"></i></td>
                                            <td className={permissionGroup === UserGroups.InternalClientRep ? "selected" : ''}><i className="cil-check-alt"></i></td>
                                            <td className={permissionGroup === UserGroups.InternalFinanceAIZ ? "selected" : ''}></td>
                                            <td className={permissionGroup === UserGroups.InternalReporting ? "selected" : ''}></td>
                                        </tr>
                                        <tr>
                                            <td className="text-left font-weight-bold">{t("Cession")}</td>
                                            <td className={permissionGroup === UserGroups.InternalReinsuranceTeam ? "selected" : ''}><i className="cil-check-alt"></i></td>
                                            <td className={permissionGroup === UserGroups.InternalExecutive ? "selected" : ''}><i className="cil-check-alt"></i></td>
                                            <td className={permissionGroup === UserGroups.InternalClientRep ? "selected" : ''}><i className="cil-check-alt"></i></td>
                                            <td className={permissionGroup === UserGroups.InternalFinanceAIZ ? "selected" : ''}><i className="cil-check-alt"></i></td>
                                            <td className={permissionGroup === UserGroups.InternalReporting ? "selected" : ''}><i className="cil-check-alt"></i></td>
                                        </tr>
                                        <tr>
                                            <td className="text-left font-weight-bold">{t("Reports")}</td>
                                            <td className={permissionGroup === UserGroups.InternalReinsuranceTeam ? "selected" : ''}><i className="cil-check-alt"></i></td>
                                            <td className={permissionGroup === UserGroups.InternalExecutive ? "selected" : ''}><i className="cil-check-alt"></i></td>
                                            <td className={permissionGroup === UserGroups.InternalClientRep ? "selected" : ''}><i className="cil-check-alt"></i></td>
                                            <td className={permissionGroup === UserGroups.InternalFinanceAIZ ? "selected" : ''}><i className="cil-check-alt"></i></td>
                                            <td className={permissionGroup === UserGroups.InternalReporting ? "selected" : ''}></td>
                                        </tr>
                                        <tr>
                                            <td className="text-left font-weight-bold">{t("Client Info")}</td>
                                            <td className={permissionGroup === UserGroups.InternalReinsuranceTeam ? "selected last" : ''}><i className="cil-check-alt"></i></td>
                                            <td className={permissionGroup === UserGroups.InternalExecutive ? "selected last" : ''}><i className="cil-check-alt"></i></td>
                                            <td className={permissionGroup === UserGroups.InternalClientRep ? "selected last" : ''}><i className="cil-check-alt"></i></td>
                                            <td className={permissionGroup === UserGroups.InternalFinanceAIZ ? "selected last" : ''}><i className="cil-check-alt"></i></td>
                                            <td className={permissionGroup === UserGroups.InternalReporting ? "selected last" : ''}></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                            <div className={this.props.onCancel ? "d-flex flex-nowrap pt-4 justify-content-center w-100" :"d-flex justify-content-between"}>
                                {this.props.onCancel ? null : <Link to="/users/add/assurant"><i className="cil-arrow-left mr-2"></i>{t("Back")}</Link>}
                                { this.props.onSave ?  
                                        <Button
                                        className="mr-3"
                                        style={{ minWidth: `125px` }}
                                        color="primary"
                                        onClick={this.props.onSave}
                                    >
                                        {t('Save')}
                                    </Button>
                                    :  <Link className="btn btn-primary" to={permissionGroup === UserGroups.InternalReinsuranceTeam ? "/users/add/assurant/confirm" : "/users/add/assurant/positions"}>{t("Continue")}</Link> }
                                  {this.props.onCancel ? <Button
                                        className="mr-3"
                                        style={{ minWidth: `125px` }}
                                        outline
                                        color="primary"
                                        onClick={this.props.onCancel}
                                    >
                                        {t('Cancel')}
                                    </Button> : null }
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(InternalUserPermissions);
