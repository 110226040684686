import React, { createContext, Component, useContext } from "react";
import { withOktaAuth } from "@okta/okta-react";
import i18n from "./i18n";
import Login from "./components/login/Login";

const AuthContext = createContext({
  user: null,
  groups: [],
  isAuthenticated: false,
  isPending: false,
  isUserInAnyGroups: (requiredGroups) => false,
});

export default AuthContext.Consumer;

export const useAuthContext = () => useContext(AuthContext);

export const withAuthContext = (Component) => {
  return (props) => (
    <AuthContext.Consumer>
      {(value) => <Component {...props} authContext={value} />}
    </AuthContext.Consumer>
  );
};
const excludeLocations = ["/login-support", "/activate"];
const isActivate = excludeLocations.some(function (loc) {
  return window.location.pathname.startsWith(loc);
});


class AuthController extends Component {
  state = {
    user: null,
    groups: [],
    isAuthenticated: false,
    getAccessToken: () => "",
    isUserInAnyGroups: (requiredGroups) =>
      this.isUserInAnyGroups(requiredGroups),
  };
  loadingUserDetails = false;

  componentDidUpdate() {
    this.checkAuthentication();
  }

  componentDidMount() {
    this.checkAuthentication();
  }

  isUserInAnyGroups(requiredGroups) {
    var groups = this.state.groups;
    return (
      !requiredGroups ||
      requiredGroups.length === 0 ||
      requiredGroups.filter((x) => groups.includes(x)).length > 0
    );
  }

  async checkAuthentication() {
    if (this.props.authState) {
      const isAuthenticated = this.props.authState.isAuthenticated;
      if (
        isAuthenticated !== this.state.isAuthenticated &&
        !this.loadingUserDetails
      ) {
        this.loadingUserDetails = true;
        try {
          const user = await this.props.oktaAuth.getUser();
          this.setState({
            isAuthenticated,
            user,
            groups: user ? user.groups : [],
            getAccessToken: this.props.oktaAuth.getAccessToken.bind(
              this.props.oktaAuth
            ),
            userType: getUserType(user),
          });
        } finally {
          this.loadingUserDetails = false;
        }
      }
    }
  }

  render() {
    return this.props.authState && this.props.authState?.accessToken ? (
      <AuthContext.Provider value={this.state}>
        {this.props.children}
      </AuthContext.Provider>
    ) : (
      !isActivate && <Login />
    );
  }
}

const getUserType = (user) => {
  var groups = user ? user.groups : [];
  if (groups.indexOf(UserTypeGroups.ExternalGroup) > -1) {
    return UserTypes.External;
  } else if (groups.indexOf(UserTypeGroups.InternalGroup) > -1) {
    return UserTypes.Internal;
  } else {
    return "";
  }
};

const UserTypeGroups = {
  ExternalGroup: "GARM External Users",
  InternalGroup: "GARM Internal Users",
};

export const UserGroups = {
  ExternalClientAdmin: {
    oktaName: "GARM AppRole_Ext_ClientAdmin",
    prettyName: i18n.t("External Client Admin"),
  },
  ExternalClientFull: {
    oktaName: "GARM AppRole_Ext_ClientFull",
    prettyName: i18n.t("External Client (Full)"),
  },
  ExternalClientDashboard: {
    oktaName: "GARM AppRole_Ext_ClientDashboard",
    prettyName: i18n.t("External Client (Dashboard)"),
  },
  ExternalThirdPartyServiceProvider: {
    oktaName: "GARM AppRole_Ext_ThirdPartyServiceProvider",
    prettyName: i18n.t("External Third Party Service Provider"),
  },
  ExternalCaptiveManager: {
    oktaName: "GARM AppRole_Ext_CaptiveManager",
    prettyName: i18n.t("External Captive Manager"),
  },
  ExternalClaimsManager: {
    oktaName: "GARM AppRole_Ext_ClaimsManager",
    prettyName: i18n.t("External Claims Manager"),
  },
  InternalReinsuranceTeam: {
    oktaName: "GARM AppRole_Int_InternalReinsuranceTeam",
    prettyName: i18n.t("Internal Reinsurance Team"),
  },
  InternalExecutive: {
    oktaName: "GARM AppRole_Int_Executive",
    prettyName: i18n.t("Internal Executive"),
  },
  InternalClientRep: {
    oktaName: "GARM AppRole_Int_ClientRep",
    prettyName: i18n.t("Internal Client Rep"),
  },
  InternalFinanceAIZ: {
    oktaName: "GARM AppRole_Int_FinanceAIZ",
    prettyName: i18n.t("Internal Finance AIZ"),
  },
  InternalReporting: {
    oktaName: "GARM AppRole_Int_Reporting",
    prettyName: i18n.t("Internal Reporting"),
  },
};

export const UserPermissionGroupNames = {
  ReportsMenu: [
    UserGroups.InternalReinsuranceTeam.oktaName,
    UserGroups.InternalExecutive.oktaName,
    UserGroups.InternalClientRep.oktaName,
    UserGroups.InternalFinanceAIZ.oktaName,
    UserGroups.InternalReporting.oktaName,
    UserGroups.ExternalClientAdmin.oktaName,
    UserGroups.ExternalClientFull.oktaName,
    UserGroups.ExternalClientDashboard.oktaName,
    UserGroups.ExternalCaptiveManager.oktaName,
    UserGroups.ExternalThirdPartyServiceProvider.oktaName,
  ],
  Summary: [
    UserGroups.InternalReinsuranceTeam.oktaName,
    UserGroups.InternalExecutive.oktaName,
    UserGroups.InternalClientRep.oktaName,
    UserGroups.ExternalClientAdmin.oktaName,
    UserGroups.ExternalClientFull.oktaName,
    UserGroups.ExternalClientDashboard.oktaName,
  ],
  ProfitDevelopment: [
    UserGroups.InternalReinsuranceTeam.oktaName,
    UserGroups.InternalExecutive.oktaName,
    UserGroups.InternalClientRep.oktaName,
    UserGroups.ExternalClientAdmin.oktaName,
    UserGroups.ExternalClientFull.oktaName,
    UserGroups.ExternalClientDashboard.oktaName,
  ],
  FranchiseLoss: [
    UserGroups.InternalReinsuranceTeam.oktaName,
    UserGroups.InternalExecutive.oktaName,
    UserGroups.InternalClientRep.oktaName,
    UserGroups.ExternalClientAdmin.oktaName,
    UserGroups.ExternalClientFull.oktaName,
    UserGroups.ExternalClientDashboard.oktaName,
    UserGroups.ExternalClaimsManager.oktaName,
  ],
  ClaimsTrends: [
    UserGroups.InternalReinsuranceTeam.oktaName,
    UserGroups.InternalExecutive.oktaName,
    UserGroups.InternalClientRep.oktaName,
    UserGroups.ExternalClientAdmin.oktaName,
    UserGroups.ExternalClientFull.oktaName,
    UserGroups.ExternalClientDashboard.oktaName,
    UserGroups.ExternalClaimsManager.oktaName,
  ],
  NewBusiness: [
    UserGroups.InternalReinsuranceTeam.oktaName,
    UserGroups.InternalExecutive.oktaName,
    UserGroups.InternalClientRep.oktaName,
    UserGroups.ExternalClientAdmin.oktaName,
    UserGroups.ExternalClientFull.oktaName,
    UserGroups.ExternalClientDashboard.oktaName,
  ],
  Cession: [
    UserGroups.InternalReinsuranceTeam.oktaName,
    UserGroups.InternalExecutive.oktaName,
    UserGroups.InternalClientRep.oktaName,
    UserGroups.InternalFinanceAIZ.oktaName,
    UserGroups.InternalReporting.oktaName,
    UserGroups.ExternalClientAdmin.oktaName,
    UserGroups.ExternalClientFull.oktaName,
    UserGroups.ExternalCaptiveManager.oktaName,
    UserGroups.ExternalThirdPartyServiceProvider.oktaName,
  ],
  Reports: [
    UserGroups.InternalReinsuranceTeam.oktaName,
    UserGroups.InternalExecutive.oktaName,
    UserGroups.InternalClientRep.oktaName,
    UserGroups.InternalFinanceAIZ.oktaName,
    UserGroups.ExternalClientAdmin.oktaName,
    UserGroups.ExternalClientFull.oktaName,
    UserGroups.ExternalClientDashboard.oktaName,
  ],
  ClientInfoReport: [
    UserGroups.InternalReinsuranceTeam.oktaName,
    UserGroups.InternalExecutive.oktaName,
    UserGroups.InternalClientRep.oktaName,
    UserGroups.InternalFinanceAIZ.oktaName,
  ],
  ManageUsersAndClients: [
    UserGroups.ExternalClientAdmin.oktaName,
    UserGroups.InternalReinsuranceTeam.oktaName,
  ],
  ViewClientPositions: [
    UserGroups.InternalReinsuranceTeam.oktaName,
    UserGroups.InternalExecutive.oktaName,
    UserGroups.InternalClientRep.oktaName,
    UserGroups.InternalFinanceAIZ.oktaName,
  ],
  ManageClientPositions: [UserGroups.InternalReinsuranceTeam.oktaName],
  AuthenticatedUser: [
    UserGroups.InternalReinsuranceTeam.oktaName,
    UserGroups.InternalExecutive.oktaName,
    UserGroups.InternalClientRep.oktaName,
    UserGroups.InternalFinanceAIZ.oktaName,
    UserGroups.InternalReporting.oktaName,
    UserGroups.ExternalClientAdmin.oktaName,
    UserGroups.ExternalClientFull.oktaName,
    UserGroups.ExternalClientDashboard.oktaName,
    UserGroups.ExternalThirdPartyServiceProvider.oktaName,
    UserGroups.ExternalCaptiveManager.oktaName,
    UserGroups.ExternalClaimsManager.oktaName,
  ],
};

export const ExternalUserGroups = [
  UserGroups.ExternalClientAdmin,
  UserGroups.ExternalClientFull,
  UserGroups.ExternalClientDashboard,
  UserGroups.ExternalThirdPartyServiceProvider,
  UserGroups.ExternalCaptiveManager,
  UserGroups.ExternalClaimsManager,
];

export const InternalUserGroups = [
  UserGroups.InternalReinsuranceTeam,
  UserGroups.InternalExecutive,
  UserGroups.InternalClientRep,
  UserGroups.InternalFinanceAIZ,
  UserGroups.InternalReporting,
];

export const UserTypes = {
  Internal: "Internal",
  External: "External",
};

const UserGroupsMap = Object.values(UserGroups);

export const getUserGroupPrettyName = (oktaName) => {
  let prettyName = "";
  var response = UserGroupsMap.filter((i) => i.oktaName === oktaName);
  if (response.length > 0) {
    prettyName = response[0].prettyName;
  }
  return prettyName;
};

export const getUserGroup = (oktaName) => {
  var response = UserGroupsMap.filter((i) => i.oktaName === oktaName);
  if (response.length > 0) {
    return response[0];
  }
  return null;
};

export const AuthProvider = withOktaAuth(AuthController);
