import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import config from '../../config';
import { withTranslation } from 'react-i18next';
import { withAuthContext } from '../../Auth';
import { Button } from 'reactstrap';

import "../../scss/useradmin/_invite_user.scss";

class SelectClientPositions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            clients: [],
            selectedClients: [],
            clientsLoaded: false,
            clientSearchFilter: "",
            positionSearchFilter: ""
        };

    }

    async componentDidMount() {
        await this.loadClientPositions();
    }

    async componentDidUpdate() {
        await this.loadClientPositions();
    }

    async loadClientPositions() {
        let { authContext } = this.props;
        const accessToken = await authContext.getAccessToken();
        if (!this.state.clientsLoaded && accessToken) {


            let response = await fetch(`${config.apiUrl}users/clientpositions`, {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + accessToken,
                })
            });

            if (response.ok) {
                // Success! 
                const clients = await response.json();
                const selectedPositions = this.props.selectedPositions;
                const selectedClients = [];
                if (selectedPositions && selectedPositions.length > 0) {
                    for (const client of clients.clients) {
                        for (const position of client.positions) {
                            if (selectedPositions.find((p) => p.id === position.id)) {
                                selectedClients.push(client);
                                break;
                            }
                        }
                    }
                }
                this.setState({ clients: [...clients.clients], selectedClients, clientsLoaded: true, submitStatus: true, renderAlertBox: true, submitted: true });
            } else {
                // Failed
                this.setState({ clients: [], clientsLoaded: false, submitStatus: false, renderAlertBox: true, submitted: true });
            }
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
    }

    selectClientPosition = (client, position, checked) => {
        const previousPositions = this.props.selectedPositions
        if (checked) {
            this.props.setPositions([...previousPositions, { ...position, clientName: client.clientName }]);
        } else {
            var index = previousPositions.findIndex(p => p.id === position.id)
            if (index > -1) {
                previousPositions.splice(index, 1);
                this.props.setPositions(previousPositions);
            }
        }
    }

    selectAllPositions = (checked) => {
        const previousPositions = this.props.selectedPositions
        const selectedClients = this.state.selectedClients;

        if (checked) {
            for (const client of selectedClients) {
                for (const position of client.positions.filter(this.matchesPositionsSearch)) {
                    const index = previousPositions.findIndex(p => p.id === position.id)
                    if (index === -1) {
                        previousPositions.push({ ...position, clientName: client.clientName });
                    }
                }
            }
        } else {
            for (const client of selectedClients) {
                for (const position of client.positions.filter(this.matchesPositionsSearch)) {
                    const index = previousPositions.findIndex(p => p.id === position.id)
                    if (index > -1) {
                        previousPositions.splice(index, 1);
                    }
                }
            }
        }
        this.props.setPositions(previousPositions);
    }

    isPositionSelected = (position) => {
        return this.props.selectedPositions.findIndex(p => p.id === position.id) > -1;
    }

    allPositionsSelected = () => {
        let positionCount = 0
        const selectedClients = this.state.clients.filter((c) => this.isClientChecked(c));
        if (selectedClients.length === 0) {
            return false;
        }
        positionCount = selectedClients.map((c) => c.positions.length).reduce((prev, cur) => prev + cur);
        return this.props.selectedPositions.length === positionCount;
    }


    selectClient = (client, checked) => {
        const previousPositions = this.props.selectedPositions
        const selectedClients = this.state.selectedClients;

        if (checked) {
            this.setState({ selectedClients: [...selectedClients, client] });
        } else {
            for (const position of client.positions) {
                let index = previousPositions.findIndex(p => p.id === position.id)
                if (index > -1) {
                    previousPositions.splice(index, 1);
                }
            }
            this.props.setPositions(previousPositions);
            selectedClients.splice(selectedClients.findIndex((c) => c.id === client.id), 1);
            this.setState({ selectedClients: selectedClients });

        }
    }

    allClientsSelected = () => {
        return this.state.clients && this.state.selectedClients 
               && this.state.selectedClients.length === this.state.clients.length;
    }

    selectAllClients = (clients, checked) => {

        const previousPositions = this.props.selectedPositions
        const selectedClients = this.state.selectedClients;
        if (checked) {
            for (const client of clients) {
                let clientSelected = selectedClients.findIndex(c => c.id === client.id) > -1;
                if (!clientSelected) {
                    selectedClients.push(client);
                }
            }
            this.setState({ selectedClients: selectedClients });
        } else {
            for (const client of clients) {
                for (const position of client.positions) {
                    let index = previousPositions.findIndex(p => p.id === position.id)
                    if (index > -1) {
                        previousPositions.splice(index, 1);
                    }
                }
                let clientIndex = selectedClients.findIndex(c => c.id === client.id);
                if (clientIndex > -1) {
                    selectedClients.splice(clientIndex, 1);
                }
            }

            this.setState({ selectedClients: selectedClients});
        }
    };

    isClientChecked = (client) => {
        return this.state.selectedClients.findIndex((c) => c.id === client.id) > -1;
    }

    handleClientSearch = (e) => {
        this.setState({ clientSearchFilter: e.target.value });
    }

    handlePositionSearch = (e) => {
        this.setState({ positionSearchFilter: e.target.value });
    }

    matchesPositionsSearch = (position) => {
        const { positionSearchFilter } = this.state;
        return positionSearchFilter ? position.seriesNumber.toLowerCase().indexOf(positionSearchFilter.toLowerCase()) > -1 : true;
    }

    render() {
        const { clients, clientSearchFilter } = this.state;

        const filteredClients = clients && clientSearchFilter ? clients.filter((c) => c.clientName.toLowerCase().indexOf(clientSearchFilter.toLowerCase()) > -1) : clients;
        const { t, nextStep, previousStep, headerText } = this.props;
        return (
            <div className="addUser">
                <div className="MyProfile-header">
                    <div className="card addUserCard">
                        <div className="card-header d-flex justify-content-between">
                            <span>{headerText ? headerText : t("Select Clients & Positions")}</span><span>{headerText ? "" : t('Step 4 of 5')}</span>
                        </div>
                        <div className="card-body">
                            <div className="tables my-1 d-flex justify-content-center">
                                <div className="card mr-4">
                                    <div className="card-header d-flex align-items-center justify-content-between">
                                        <span>{t('Clients')}</span>
                                        <div className="d-flex justify-content-end">
                                            <input type="text" className="form-control" placeholder={t('Search...')} name="search" onChange={this.handleClientSearch} />
                                            <div className="form-icon"><i className="cil-magnifying-glass"></i></div>
                                        </div>
                                    </div>
                                    <div className="scrollable">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col">{t('Name')}</th>
                                                    <th scope="col" className="text-center"><input className="mr-1" type="checkbox" checked={this.allClientsSelected()} onChange={(e) => this.selectAllClients(filteredClients, e.target.checked)}></input>{t('Select All')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredClients.map((client, index) => {
                                                    return (
                                                        <tr key={client.id}>
                                                            <td>{client.clientName}</td>
                                                            <td className="text-center"><input
                                                                className="user-checkbox"
                                                                key={index}
                                                                type="checkbox"
                                                                name="user"
                                                                checked={this.isClientChecked(client)}
                                                                onChange={(e) => this.selectClient(client, e.target.checked)}
                                                            ></input></td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="card w-50">
                                    <div className="card-header d-flex align-items-center justify-content-between">
                                        <span>{t('Client Positions')}</span>
                                        <div className="d-flex justify-content-end">
                                            <input type="text" className="form-control" placeholder={t('Search...')} name="search" onChange={this.handlePositionSearch} />
                                            <div className="form-icon"><i className="cil-magnifying-glass"></i></div>
                                        </div>
                                    </div>
                                    <div className="scrollable">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col">{t('Name')}</th>
                                                    <th scope="col">{t('Positions')}</th>
                                                    <th scope="col" className="text-center"><input className="mr-1" type="checkbox" checked={this.allPositionsSelected()} onChange={(e) => this.selectAllPositions(e.target.checked)}></input>{t('Select All')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {clients.filter((c) => this.isClientChecked(c)).map((client) => {
                                                    return client.positions ?
                                                        client.positions.filter(this.matchesPositionsSearch).map((position, index) =>
                                                            <tr key={position.id}>
                                                                <td>{index === 0 ? client.clientName : ''}</td>
                                                                <td>{position.seriesNumber}</td>
                                                                <td className="text-center"> <input type="checkbox" checked={this.isPositionSelected(position)} onChange={(e) => this.selectClientPosition(client, position, e.target.checked)}></input></td>
                                                            </tr>
                                                        ) : null;


                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className={this.props.onCancel ? "d-flex flex-nowrap pt-4 justify-content-center w-100" :"d-flex justify-content-between"}>
                                {this.props.onCancel ? null : <Link to={previousStep}><i className="cil-arrow-left mr-2"></i>{t('Back')}</Link>}
                               { this.props.onSave ?  
                                        <Button
                                        className="mr-3"
                                        style={{ minWidth: `125px` }}
                                        color="primary"
                                        onClick={this.props.onSave}
                                    >
                                        {t('Save')}
                                    </Button>
                                    :   <Button tag={Link} color="primary" to={nextStep} disabled={this.props.selectedPositions.length === 0}>{t('Continue')}</Button>
                               }
                               {this.props.onCancel ? <Button
                                        className="mr-3"
                                        style={{ minWidth: `125px` }}
                                        outline
                                        color="primary"
                                        onClick={this.props.onCancel}
                                    >
                                        {t('Cancel')}
                                    </Button> : null }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(withAuthContext(SelectClientPositions));
