import React, { useState } from "react";
import classNames from "classnames";
import { Collapse, NavItem, NavLink } from "reactstrap";
import { Link, useLocation } from "react-router-dom";

const ReportsMenu = ({ reports, name, level, onLeafClick }) => {
  const reportsKeys = Object.keys(reports);
  const isLeaf = reportsKeys.includes("id");
  const reportIsNotEmpty = reportsKeys.length > 0;
  const reportIsObject = typeof reports === "object" && reports !== null;

  const haveChildren = !isLeaf && reportIsNotEmpty && reportIsObject;

  const [collapsed, setCollapsed] = useState(level !== 0);
  const location = useLocation();

  const toggle = () =>
    setCollapsed((prev) => {
      return !prev;
    });

  const style = {
    paddingLeft: `${21 * level}px`,
  };

  function getRootIconFromName() {
    let nameNormalized = name.toLowerCase();
    switch (nameNormalized) {
      case "dashboards":
        return "cil-chart-pie";
      case "reports":
        return "cil-chart-line";
      default:
        return "cil-3d";
    }
  }

  function renderAsLeaf() {
    return (
      <NavItem key={reports.id} className="text-initial" style={style}>
        <NavLink
          key={reports.id}
          tag={Link}
          active={reports.target === location.pathname}
          to={reports.target}
          onClick={onLeafClick}
        >
          {reports.title}
        </NavLink>
      </NavItem>
    );
  }

  function rednderAsRoot() {
    return (
      <>
        <NavItem
          onClick={toggle}
          className={classNames({ "menu-open": !collapsed })}
          style={style}
        >
          <NavLink className="dropdown-toggle text-uppercase">
            <i className={getRootIconFromName(name) + " rm-3"}></i>
            {name}
          </NavLink>
        </NavItem>
        <Collapse
          isOpen={!collapsed}
          navbar
          className={classNames("items-menu")}
        >
          {reportsKeys.map((key) => (
            <ReportsMenu
              key={key}
              reports={reports[key]}
              name={key}
              level={level + 1}
              onLeafClick={onLeafClick}
            />
          ))}
        </Collapse>
      </>
    );
  }

  function renderAsBranch() {
    return (
      <>
        <NavItem
          onClick={toggle}
          className={classNames({ "menu-open": !collapsed })}
          style={style}
        >
          <NavLink className="dropdown-toggle">{name}</NavLink>
        </NavItem>
        <Collapse
          isOpen={!collapsed}
          navbar
          className={classNames("items-menu")}
        >
          {reportsKeys.map((key) => (
            <ReportsMenu
              key={key}
              reports={reports[key]}
              name={key}
              level={level + 1}
              onLeafClick={onLeafClick}
            />
          ))}
        </Collapse>
      </>
    );
  }

  if (level === 0) {
    return rednderAsRoot();
  }
  return haveChildren ? renderAsBranch() : renderAsLeaf();
};

export default ReportsMenu;
