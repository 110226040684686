import React, { useState, useEffect } from "react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import SideBar from "./components/layout/SideBar";
import Content from "./components/layout/Content";
import TopBar from "./components/layout/TopBar";
import ProfileMenu from "./components/layout/ProfileMenu";
import NotificationsMenu from "./components/layout/NotificationsMenu";
import { Security } from "@okta/okta-react";
import config from "./config";
import "./App.scss";
import "@coreui/icons/css/free.css";
import { withRouter, Route, useHistory } from "react-router-dom";
import { AuthProvider } from "./Auth";
import { useDispatch } from "react-redux";
import { ReportsProvider } from "./Reports";
import PageLoader from "../src/components/general/PageLoader";
import Login from "../src/components/login/Login";

function App(props) {
  const [sidebarIsOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);

  const [profileMenuIsOpen, setProfileMenuOpen] = useState(false);
  const toggleProfileMenu = () => setProfileMenuOpen(!profileMenuIsOpen);

  const [notificationsMenuIsOpen, setNotificationsMenuOpen] = useState(false);
  const toggleNotificationsMenu = () =>
    setNotificationsMenuOpen(!notificationsMenuIsOpen);

  const [oktaAuth, setOktaAuth] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const dispatch = useDispatch();

  const history = useHistory();

  useEffect(() => {
    localStorage.clear();
    const oktaConfig = {
      issuer: config.oktaIssuerUrl,
      clientId: config.oktaClientId,
      redirectUri: config.oktaSigninRedirectUrl,
      features: {
        passwordlessAuth: true,
      },
    };

    setOktaAuth(new OktaAuth(oktaConfig));
    setIsLoaded(true);
  }, [dispatch]);

  const { location } = props;

  const excludeLocations = ["/login", "/login-support", "/activate"];
  const isActivate = excludeLocations.some(function (loc) {
    return location.pathname.startsWith(loc);
  });
  const isFullScreen =
    excludeLocations.includes(location.pathname) || isActivate;

  let isFromiFrame = false;

  const prevURL = window.location.ancestorOrigins[0];
  sessionStorage.setItem("currentEnv", process.env.NODE_ENV);

  if (
    prevURL &&
    (prevURL.includes("warrantygroup") ||
      prevURL.includes("twgservice--autohubin3") ||
      prevURL.includes("twgservice--twgqa1d") ||
      prevURL.includes("sandbox") ||
      prevURL.includes("assurantauto"))
  ) {
    sessionStorage.setItem("isFromiFrame", "true");
    isFromiFrame = true;
    console.log("sessionStorage " + isFromiFrame);
  } else {
    sessionStorage.setItem("isFromiFrame", "false");
    window.location = config.AutoHubURL;
  }

  const restoreOriginalUri = async (oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  let ifAutohub = sessionStorage.getItem("isFromiFrame");

  if (!isLoaded) {
    return <PageLoader></PageLoader>;
  } else {
    return (
      <div className="App w-100">
        {oktaAuth && (
          <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            {!isLoaded && <PageLoader />}
            <AuthProvider>
              <ReportsProvider>
                {ifAutohub !== "true" && (
                  <TopBar
                    toggleSidebar={toggleSidebar}
                    toggleProfileMenu={toggleProfileMenu}
                    excludeLocations={excludeLocations}
                    toggleNotificationsMenu={toggleNotificationsMenu}
                  />
                )}

                <ProfileMenu
                  toggle={toggleProfileMenu}
                  isOpen={profileMenuIsOpen}
                  excludeLocations={excludeLocations}
                />
                {notificationsMenuIsOpen && (
                  <NotificationsMenu
                    toggle={toggleNotificationsMenu}
                    isOpen={notificationsMenuIsOpen}
                    excludeLocations={excludeLocations}
                  />
                )}
                <main
                  className={
                    isFullScreen ? "full-screen" : "d-flex align-items-stretch"
                  }
                >
                  <SideBar
                    toggle={toggleSidebar}
                    isOpen={sidebarIsOpen}
                    excludeLocations={excludeLocations}
                    isFromAutohub={ifAutohub === "true" ? true : false}
                  />
                  <Content
                    toggleSidebar={toggleSidebar}
                    sidebarIsOpen={sidebarIsOpen}
                    excludeLocations={excludeLocations}
                  />
                </main>
              </ReportsProvider>
            </AuthProvider>
            <Route path="/login" component={Login} />
          </Security>
        )}
      </div>
    );
  }
}

export default withRouter(App);
