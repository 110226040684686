import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { Navbar } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ellipsis from '../../images/ellipsis.svg';
import '../../scss/components/_topbar.scss';
import { ReportPrintButton } from '../general/ReportPrintButton';
import { withAuthContext } from '../../Auth';

class TopBar extends Component {

  render() {
    const { t, toggleSidebar, toggleProfileMenu, location, excludeLocations, authContext } = this.props;

    const userName = authContext.user ? authContext.user.name : null;
    const firstName = authContext.user ? authContext.user.given_name : null;
    const lastName = authContext.user ? authContext.user.family_name : null;
    const initials = firstName && lastName ? `${firstName.charAt(0)}${lastName.charAt(0)}` : null;
    let isActivate = excludeLocations.some(function (loc) { return location.pathname.startsWith(loc); });

    return (!(excludeLocations.includes(location.pathname) || isActivate) ?
      <Navbar
        color="light"
        light
        className="navbar shadow p-2 bg-white"
        expand="md"
      >
        <div className="logo text-left">
          <Link to="/"><img alt={t('Assurant')} src="../images/assurant-logo.png"></img></Link>
        </div>
        
        <div className="toggle-menu toggle-sidebar" onClick={toggleSidebar}>
          <i className="cil-menu" ></i>
        </div>
        <div className="topbar-user d-flex flex-nowrap justify-content-center align-items-center">          

          {/* <div className="notification mr-3" onClick={toggleNotificationsMenu}>
            <i className="cil-bell" ></i><span className="badge badge-pill badge-danger">3</span>
          </div> */} {/* Notification Feature (Future) */}

          <Link to="/user-profile" className="profile-link pl-4 d-flex flex-nowrap justify-content-center align-items-center">
            <span className="profile-image">{initials}</span>
            <span className="profile-name">{userName}</span>
          </Link>
        </div>

        <div className="toggle-menu toggle-profile-menu" onClick={toggleProfileMenu}>
          <img src={ellipsis} alt="Toggle Profile Menu" />
        </div>

      </Navbar> : ''
    );
  }


};

export default withTranslation()(withAuthContext(withRouter(TopBar)));
