import React from "react";
import "../../App.scss";
import Footer from "./Footer";
import { Container } from "reactstrap";
import { Route, withRouter } from "react-router-dom";
import Login from "../login/Login";
import LoginSupport from "../login/LoginSupport";
import classNames from "classnames";
import loadable from "@loadable/component";
import { LoginCallback } from "@okta/okta-react";
import HomePage from "../../pages/HomePage";
import RouteWhenMemberOfAny from "../general/RouteWhenMemberOfAny";
import { UserPermissionGroupNames, UserGroups } from "../../Auth";
import { ReportPrintButton } from '../general/ReportPrintButton';
import InternalUserInvitationSteps from "../useradmin/InternalUserInvitationSteps";

const UserList = loadable(() => import("../useradmin/UserList"));
const InviteUsers = loadable(() => import("../useradmin/InviteUsers"));
const UserProfile = loadable(() => import("../useradmin/UserProfile"));
const Support = loadable(() => import("../../pages/SupportPage"));
const ReportsConfig = loadable(() => import("../../pages/ReportsConfigPage"));
const EditReport = loadable(() => import("../../pages/EditReport"));
const ClientPositionList = loadable(() =>
  import("../clientpositionadmin/ClientPositionList")
);
const ClientPositionView = loadable(() =>
  import("../clientpositionadmin/ClientPositionView")
);
const ClientPositionAdd = loadable(() =>
  import("../clientpositionadmin/ClientPositionAdd")
);
const ClientPositionEdit = loadable(() =>
  import("../clientpositionadmin/ClientPositionEdit")
);
const ExternalUserInvitationSteps = loadable(() =>
  import("../useradmin/ExternalUserInvitationSteps")
);

const DataApprovals = loadable(() => import("../../pages/DataApprovalsPage"));
const ReportPage = loadable(() => import("../../pages/ReportPage"));
const ActivateUser = loadable(() => import("../login/ActivateUser"));

function Content(props) {
  const { sidebarIsOpen, excludeLocations, location } = props;
  let isActivate = excludeLocations.some(function (loc) {
    return location.pathname.startsWith(loc);
  });
  let isReportPage = location.pathname.startsWith("/report");

  return (
    <Container
      className={classNames(
        "content",
        { "is-open": sidebarIsOpen },
        { "report-page": isReportPage },
        "d-flex",
        "flex-wrap",
        "justify-content-center",
        "p-0",
        "mw-100"
      )}
    >
      <div
        className={
          !(excludeLocations.includes(location.pathname) || isActivate)
            ? isReportPage
              ? "w-100 pl-xs-4 pl-md-5 py-3 inner-content"
              : "w-100 px-xs-4 px-md-5 py-3 inner-content"
            : "w-100 p-0"
        }
      >

      {isReportPage ? (<ReportPrintButton></ReportPrintButton>) :'' }    

        <RouteWhenMemberOfAny
          groups={UserPermissionGroupNames.ManageUsersAndClients}
          exact
          path="/users"
          render={({ component }) => component()}
          component={UserList}
        ></RouteWhenMemberOfAny>

        <RouteWhenMemberOfAny
          groups={UserPermissionGroupNames.ManageUsersAndClients}
          exact
          path="/users/add"
          render={({ component }) => component()}
          component={InviteUsers}
        ></RouteWhenMemberOfAny>

        <RouteWhenMemberOfAny
          groups={UserPermissionGroupNames.ManageUsersAndClients}
          path="/users/add/client"
          render={({ component }) => component()}
          component={ExternalUserInvitationSteps}
        ></RouteWhenMemberOfAny>
        <RouteWhenMemberOfAny
          groups={UserPermissionGroupNames.ManageUsersAndClients}
          path="/users/add/assurant"
          render={({ component }) => component()}
          component={InternalUserInvitationSteps}
        ></RouteWhenMemberOfAny>

        <RouteWhenMemberOfAny
          groups={UserPermissionGroupNames.ViewClientPositions}
          exact
          path="/clientpositions"
          render={({ component }) => component()}
          component={ClientPositionList}
        ></RouteWhenMemberOfAny>

        <RouteWhenMemberOfAny
          groups={UserPermissionGroupNames.ManageClientPositions}
          path="/clientpositions/add"
          render={({ component }) => component()}
          component={ClientPositionAdd}
        ></RouteWhenMemberOfAny>

        <RouteWhenMemberOfAny
          groups={UserPermissionGroupNames.ManageClientPositions}
          path="/clientposition/edit/:clientPositionId?"
          render={({ component }) => component()}
          component={ClientPositionEdit}
        ></RouteWhenMemberOfAny>

        <RouteWhenMemberOfAny
          groups={UserPermissionGroupNames.ViewClientPositions}
          exact
          path="/clientposition/view/:clientPositionId?"
          render={({ component }) => component()}
          component={ClientPositionView}
        ></RouteWhenMemberOfAny>

        <RouteWhenMemberOfAny
          groups={UserPermissionGroupNames.ManageClientPositions}
          exact
          path="/data-approvals"
          render={({ component }) => component()}
          component={DataApprovals}
        ></RouteWhenMemberOfAny>

        <RouteWhenMemberOfAny
          groups={UserPermissionGroupNames.AuthenticatedUser}
          exact
          path="/user-profile/:userId?"
          render={({ component }) => component()}
          component={UserProfile}
        ></RouteWhenMemberOfAny>

        <RouteWhenMemberOfAny
          groups={[UserGroups.InternalReinsuranceTeam.oktaName]}
          exact
          path="/edit-report/:reportId?"
          render={({ component }) => component()}
          component={EditReport}
        ></RouteWhenMemberOfAny>

        <RouteWhenMemberOfAny
          groups={UserPermissionGroupNames.AuthenticatedUser}
          exact
          path="/support"
          render={({ component }) => component()}
          component={Support}
        ></RouteWhenMemberOfAny>

        <RouteWhenMemberOfAny
          groups={[UserGroups.InternalReinsuranceTeam.oktaName]}
          exact
          path="/reportsConfig"
          render={({ component }) => component()}
          component={ReportsConfig}
        ></RouteWhenMemberOfAny>

        <RouteWhenMemberOfAny
          groups={UserPermissionGroupNames.AuthenticatedUser}
          exact
          path="/"
          render={({ component }) => component()}
          component={HomePage}
        ></RouteWhenMemberOfAny>

        <RouteWhenMemberOfAny
          groups={UserPermissionGroupNames.AuthenticatedUser}
          exact
          path="/report/:reportId/:nav?"
          render={({ component }) => component()}
          component={ReportPage}
        ></RouteWhenMemberOfAny>

        <Route path="/login" component={Login} />
        <Route path="/login-support" component={LoginSupport} />
        <Route path="/signin-oidc" component={LoginCallback}></Route>
        <Route path="/activate/:activationToken?" component={ActivateUser} />
      </div>

      {/* <div className="w-100 p-0 inner-content">


      </div> */}
      <Footer
        sidebarIsOpen={sidebarIsOpen}
        excludeLocations={excludeLocations}
      />
    </Container>
  );
}

export default withRouter(Content);
