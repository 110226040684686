import { createSlice } from '@reduxjs/toolkit'

export const reportsSlice = createSlice({
    name: 'reportsStore',
    initialState: {
        pages: [],
        appliedFilters: [],
        reportTitle: ''
    },
    reducers: {
        setPages: (state, action) => {
            state.pages = [
                ...action.payload
            ];
        },
        setAppliedFilters: (state, action) => {
            state.appliedFilters = [
                ...action.payload
            ];
        },
        setReportTitle: (state, action) => {
            state.reportTitle = action.payload;
        }
    }

})


export const selectPages = state => state.reportsStore.pages;
export const selectAppliedFilters = state => state.reportsStore.appliedFilters;
export const selectReportTitle = state => state.reportsStore.reportTitle;

// Don't expose to rest of the app.
const {
    setPages,
    setAppliedFilters,
    setReportTitle
} = reportsSlice.actions


export const setCurrentPages = (pages) => async dispatch => {
    dispatch(setPages(pages));
}

export const setCurrentAppliedFilters = (appliedFilters) => async dispatch => {
    dispatch(setAppliedFilters(appliedFilters));
}

export const setCurrentReportTitle = (reportTitle) => async dispatch => {
    console.log("reportTitle " + reportTitle);
    let PageLoc = "";

    switch (reportTitle) {
        case "Summary":
            reportTitle = "dashboard - summary";
            PageLoc = "dashboard/summary";
            break;
        case "Loss Ratio Trend":
            reportTitle = "dashboard - loss ratio trend ";
            PageLoc = "dashboard/loss_ratio_trend";
            break;
        case "Paid Claims":
            reportTitle = "dashboard - paid claims";
            PageLoc = "dashboard/paid_claims";
            break;
        case "Written Business":
            reportTitle = "dashboard - written business";
            PageLoc = "dashboard/written_business";
            break;
        case "Product Analysis":
            reportTitle = "dashboard - product analysis";
            PageLoc = "dashboard/product_analysis";
            break;

        case "/report/loss_ratio_trend":
            reportTitle = "dashboard - loss ratio trend ";
            PageLoc = "dashboard/loss_ratio_trend";
            break;
        case "/report/written_business":
            reportTitle = "dashboard - written business";
            PageLoc = "dashboard/written_business";
            break;
        case "/report/product_analysis":
            reportTitle = "dashboard - product analysis";
            PageLoc = "dashboard/product_analysis";
            break;
        case "/report/paid_claims":
            reportTitle = "dashboard - paid claims";
            PageLoc = "dashboard/paid_claims";
            break;

        case "Miles":
            reportTitle = "reports - cessions - miles";
            PageLoc = "reports/cessions_miles";
            break;
        case "Months":
            reportTitle = "reports - cessions - months";
            PageLoc = "reports/cessions_months";
            break;
        case "Multi-Period":
            reportTitle = "reports - cessions - multi period ";
            PageLoc = "reports/multi_period";
            break;
        case "Cession Extract":
            reportTitle = "reports - cessions - cession extract";
            PageLoc = "reports/cession_extract";
            break;
        case "Cession Statement":
            reportTitle = "reports - cessions - cession statement";
            PageLoc = "reports/cession_statement";
            break;
        case "Store Break Out":
            reportTitle = "reports - cessions - store break out";
            PageLoc = "reports/store_break_out";
            break;

        case "Production":
            reportTitle = "reports - production";
            PageLoc = "reports/production";
            break;
        case "State Loss":
            reportTitle = "reports - state loss";
            PageLoc = "reports/state_loss";
            break;
        case "Earned Loss Ratio":
            reportTitle = "reports - earned loss ratio";
            PageLoc = "reports/earned_loss_ratio";
            break;
        case "Earned Premium":
            reportTitle = "reports - earned premium";
            PageLoc = "reports/earned_premium";
            break;
        case "Client Info":
            reportTitle = "reports - client info";
            PageLoc = "reports/client_info";
            break;
        case "Internal UAT":
            reportTitle = "reports - internal uat";
            PageLoc = "reports/internal_uat";
            break;

        case "Claim Trends":
            reportTitle = "dashboard - claim trends";
            PageLoc = "dashboard/claim_trends";
            break;
        case "New Business":
            reportTitle = "dashboard - new business";
            PageLoc = "dashboard/new_business";
            break;
        case "Profit Development":
            reportTitle = "dashboard - profit development";
            PageLoc = "dashboard/profit_development";
            break;
        case "Product Report":
            reportTitle = "dashboard - product report";
            PageLoc = "dashboard/product_report";
            break;



        default:
            reportTitle = "";
            PageLoc = "";
    }

    //let PageName = "administration - users & permissions";

    let PageLocation = window.location.origin + "/autoHub/reinsurance";
    let PageTitle = "profit participation ";    

    window.dataLayer.push({
        event: "page_view",
        page_data: {
            breadcrumb: "",
            page_category: "",
            page_category2: "",
            page_name: reportTitle,
            page_location: PageLocation + "/" + PageLoc,
            page_referrer: "",
            page_title: PageTitle + reportTitle,
            module: "reinsurance",
            user_login_state: "authenticated",
            dealer_name: "",
        },
    });

    dispatch(setReportTitle(reportTitle));
}

export default reportsSlice.reducer
