import React, { useEffect } from "react";
import { useHistory, Route } from "react-router-dom";
import { useAuthContext } from "../../Auth";
import { useOktaAuth } from "@okta/okta-react";

/*
 This is adapted from https://github.com/okta/okta-react/blob/4.1/src/SecureRoute.tsx with added functionality to check if the user has accepted the terms an conditions 
 and if the user is a member of the required groups to see the current route.
*/
const RequireAuth = ({ children, requiredGroups }) => {
  const pendingLogin = React.useRef(false);
  const { isUserInAnyGroups, isAuthenticated } = useAuthContext();
  const { authState, oktaAuth } = useOktaAuth();
  const history = useHistory();

  useEffect(() => {
    if (!authState.isAuthenticated) {
      if (!authState.isPending) {
        if (pendingLogin.current) {
          return;
        }
        pendingLogin.current = true;
        oktaAuth.setOriginalUri(); // This is the magic thing that makes Okta redirect the user to the original uri after login
        history.push(`/login`);
      }
    }
  }, [
    authState.isAuthenticated,
    authState.isPending,
    history,
    isAuthenticated,
    oktaAuth,
  ]);

  if (!isAuthenticated) {
    if (!authState?.isPending) {
      return null;
    } else if (isAuthenticated) {
      if (!authState.isPending) {
        return null;
      }
      return null;
    } else if (isAuthenticated && !isUserInAnyGroups(requiredGroups)) {
      if (!authState.isPending) {
        return <h3>Unauthorized</h3>;
      }
      return null;
    }
  }

  return <React.Fragment>{children}</React.Fragment>;
};

const RouteWhenMemberOfAny = ({ component, ...props }) => {
  const PassedComponent =
    component ||
    function () {
      return null;
    };
  const WrappedComponent = (wrappedProps) => (
    <RequireAuth requiredGroups={props.groups}>
      <PassedComponent {...wrappedProps} />
    </RequireAuth>
  );
  return (
    <Route
      {...props}
      render={(routeProps) =>
        props.render ? (
          props.render({ ...routeProps, component: WrappedComponent })
        ) : (
          <WrappedComponent {...routeProps} />
        )
      }
    />
  );
};

export default RouteWhenMemberOfAny;
