import React from 'react';
import '../../App.scss';
import { useTranslation } from 'react-i18next';
import {  withRouter } from 'react-router-dom';
import classNames from "classnames";

function Footer(props) {
  const { t } = useTranslation();
  const { location, sidebarIsOpen, excludeLocations } = props;
  let isActivate = excludeLocations.some(function (loc) { return location.pathname.startsWith(loc); })
  
  return (
          !(excludeLocations.includes(location.pathname) || isActivate) ?
          <footer className={classNames("footer", "mt-auto", "p-3", { "is-open": sidebarIsOpen }, "w-100", "text-xs-center", "text-md-right")} >
              <span>{t('Powered by Assurant Global Automotive')}</span>
          </footer>
          : ''
  );
}

export default withRouter(Footer);
