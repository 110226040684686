import React, { Component, useState } from "react";
import "../../scss/components/_sidebar.scss";
import { withTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import classNames from "classnames";
import { Nav, Collapse, NavItem, NavLink, Input } from "reactstrap";
import ReportsMenu from "../general/ReportsMenu";
import { withAuthContext } from "../../Auth";
import { withReports } from "../../Reports";
import ProfileMenu from "./ProfileMenu";
import config from "../../config";
import { useTranslation } from "react-i18next";
import SidebarAdminMenu from "./SidebarAdminMenu";

const style = {
  paddingLeft: `${21 * 2}px`,
};

class SideBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "",
      reports: props.reports,
      collapsed: false,
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  toggle() {
    this.setState({ collapsed: !this.state.collapsed });
  };

  render() {
    const { width } = this.state;
    const { t, location, toggle, isOpen, excludeLocations, isFromAutohub } = this.props;

    const isMobile = width <= 767;

    const toggleSidebarIfMobile = () => {
      if (isMobile) {
        toggle();
      }
    };

    let isActivate = excludeLocations.some(function (loc) {
      return location.pathname.startsWith(loc);
    });

    const reportsRoot = Object.keys(this.props.reports);

    const excludeLocations1 = ['/login', '/login-support', '/activate'];
    
    return !(excludeLocations.includes(location.pathname) || isActivate) ? (
      <div
        className={classNames(
          "sidebar",
          { "is-open": isMobile ? !isOpen : isOpen },
          "overflow-auto"
        )}
      >
        <div className="side-menu">
          <h3>{t("Profit Participation")}</h3>

          <Nav vertical className="list-unstyled ">
            {reportsRoot.map((report) => (
              <ReportsMenu
                reports={this.props.reports[report]}
                name={report}
                key={report}
                level={0}
                onLeafClick={toggleSidebarIfMobile}
              />
            ))}
          </Nav> 

          {isFromAutohub &&

          <Nav vertical className="list-unstyled ">
            <NavItem
              onClick={this.toggle}
              className={classNames({ "adminMenu menu-open": this.state.collapsed })}
              style={style}
            >
              <NavLink className="dropdown-toggle text-uppercase">                
                <img alt={t("admin")} src="../images/administration.svg" className="iConadminMenu"></img>
                Administration
              </NavLink>
            </NavItem>
            <Collapse
              isOpen={this.state.collapsed}
              navbar
              className={classNames("items-menu")}
            >

              <Nav vertical className="list-unstyled ">
                <SidebarAdminMenu
                  onClick={toggle}
                  className={classNames({ "menu-open": !this.state.collapsed })}
                  isOpen={false}
                  excludeLocations={excludeLocations1}
                />
              </Nav>
            </Collapse>
            <NavItem>
                <NavLink tag={Link} to={"/support"} className="text-uppercase" >
                <img alt={t("admin")} src="../images/support.svg" className="iconSupportMenu"></img>
                  {t("Support & Tutorials")}
                </NavLink>
              </NavItem>
          </Nav>
          }
          {/* <Nav vertical className="list-unstyled pb-3 text-uppercase">
          <NavItem>
                <NavLink tag={Link} to={"/support"} >
                <img alt={t("admin")} src="../images/support.svg" className="iconSupportMenu"></img>
                  {t("Support & Tutorials")}
                </NavLink>
              </NavItem>
           
          </Nav> */}
          

        </div>
      </div>
    ) : (
      ""
    );
  }
}

export default withTranslation()(
  withReports(withAuthContext(withRouter(SideBar)))
);
