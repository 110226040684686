import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { ExportReportDialog } from '../general/ExportReportDialog';
import { useTranslation } from 'react-i18next';

const ReportPrintButton = () => {
    const t = useTranslation().t;

    const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);




    async function openExportDialog(e) {
        setIsExportDialogOpen(true);
    }

    async function closeExportDialog(e) {
        setIsExportDialogOpen(false);
    }




    return (
        <>
            <Row className="pr-5">
                <Col md={{ size: 1, offset: 10 }} className="print-button">
                    <img alt={t("Download")} className='icon-download' src="../images/download.svg" onClick={openExportDialog}></img>
                    <span className='download-report' onClick={openExportDialog}>DOWNLOAD</span>
                </Col>
            </Row>

            <ExportReportDialog
                title={t('Export')}
                buttonText={t('Download')}
                isValid={true}
                onClose={closeExportDialog}
                isOpen={isExportDialogOpen}
            />


        </>
    );
}

export { ReportPrintButton };
