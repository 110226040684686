import React from "react";
import { Modal, ModalBody } from 'reactstrap';
import '../../scss/components/_alert_box.scss';
import PropTypes from 'prop-types';

const AlertBox = (props) => {

    const { title, text, onClose, isOpen, isSuccess, alertIconClass } = props;

    return (
        <div className="AlertBox" onClick={onClose}>
            <Modal isOpen={isOpen} onClick={onClose} centered autoFocus>
                <ModalBody>
                    <div className="alert-box-content text-center d-flex flex-wrap align-items-center">
                        <div className="w-100 align-self-end checkmark pt-3"><i className={alertIconClass || (isSuccess ? "cil-check-alt" : "cil-mood-very-bad")}></i></div>
                        <h3 className="w-100 font-weight-bold fade-in mt-3">{title}</h3>
                        <p className="w-100 align-self-start fade-in">{text}</p>
                    </div>
                    <div onClick={onClose} className="text-link text-center py-3">Close</div>
                </ModalBody>
            </Modal>
        </div>
    );
}

AlertBox.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    alertIconClass: PropTypes.string,
}

export default AlertBox;
