import React, { useCallback, useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import "../../scss/components/_export_report_dialog.scss";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  selectAppliedFilters,
  selectPages,
  selectReportTitle,
} from "../../features/reports/reportsSlice";
import config from "../../config";
import { useAuthContext } from "../../Auth";
import { useTranslation } from "react-i18next";
import AlertBox from "./AlertBox";
import Loader from "react-spinners/BeatLoader";

const ExportReportDialog = (props) => {
  const { buttonText, isOpen, onClose, isValid } = props;
  const [checked, setChecked] = useState([]);
  const [fileFormat, setFileFormat] = useState(1); //1 = PDF, 0 = PPTX
  const reportPages = useSelector(selectPages);
  const t = useTranslation().t;
  const appliedFilters = useSelector(selectAppliedFilters);
  const reportTitle = useSelector(selectReportTitle);
  const authContext = useAuthContext();
  const [exportCompletionPercentage, setExportCompletionPercentage] =
    useState(0);
  const [exportId, setExportId] = useState("");
  const [isExporting, setIsExporting] = useState(false);
  const [modalError, setModalError] = useState({
    isVisible: false,
    titleText: "",
    messageText: "",
    alertIconClass: null,
  });

  useEffect(() => {
    setChecked([]);
  }, [reportPages]);

  const parentFolder = "/report/";
  const currentReport = window.location.pathname
    .split(parentFolder)[1]
    ?.split("/")[0];

  async function exportReport(e) {
    e.stopPropagation();

    try {
      let pages = [];
      if (checked && checked.length > 0) {
        pages = reportPages
          .map((p) => p.name)
          .filter((p) => checked.includes(p)); // This ensures the pages are exported in the correct order
      }
      console.log(
        appliedFilters,
        currentReport,
        config.apiUrl,
        "filterapplied"
      );
      let result = await fetch(
        `${config.apiUrl}reports/${currentReport}/export`,
        {
          method: "post",
          body: JSON.stringify({
            filters: appliedFilters,
            pages: pages,
            fileFormat,
          }),
          headers: new Headers({
            Authorization: "Bearer " + (await authContext.getAccessToken()),
            "content-type": "application/json",
          }),
        }
      );
      let resultObj = await result.json();
      console.log(resultObj, "result obj");
      console.log(JSON.stringify(resultObj), "result obj in stringify");
      console.log(result, "result");
      if (result.ok) {
        setExportId(resultObj.id);
      } else {
        if (result.status === 400) {
          if (typeof resultObj.exportErrorType !== "undefined") {
            let title,
              message,
              iconClass = null;

            if (resultObj.exportErrorType === "UnsupportedFilterType") {
              title = t("Sorry... you're almost there!");
              message = t(
                "Please open the filter pane on the right. Under each filter choice, change 'Advanced filters' to 'Basic Filtering'. Then, try to export again."
              );
            } else {
              title = t("Multiple page documents");
              message = t(
                "Each tab is a separate page. To export each one, select the tab and export individually."
              );
              iconClass = "cil-warning";
            }

            showErrorModal(title, message, iconClass);
          }
        } else {
          showErrorModal(
            t("An Error Occurred"),
            t(
              "An unexpected error occurred while processing your export request. Please try again later."
            ),
            "cil-warning"
          );
        }
      }
    } catch (e) {
      showErrorModal(
        t("An Error Occurred"),
        t(
          "An unexpected error occurred while processing your export request. Please try again later."
        ),
        "cil-warning"
      );
    }
  }

  const checkReportPercentage = useCallback(async () => {
    try {
      let result = await fetch(
        `${config.apiUrl}reports/${currentReport}/export/${exportId}/status`,
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + (await authContext.getAccessToken()),
          }),
        }
      );
      if (result.ok) {
        console.log("currentReport " + currentReport);
        console.log("exportId " + exportId);
        let data = await result.json();
        // Status = 3 :success
        // Status = 4 :fail
        if (
          data.percentComplete === 100 ||
          data.status === 4 ||
          data.status === 3
        ) {
          console.log(
            `${config.apiUrl}reports/${currentReport}/export/${exportId}/file`
          );
          window.open(
            `${config.apiUrl}reports/${currentReport}/export/${exportId}/file`
          );
          setExportId("");
          setExportCompletionPercentage(0);
          onClose();
        } else {
          setExportCompletionPercentage(data.percentComplete);
          setTimeout(checkReportPercentage, 5000);
        }
      } else {
        setExportId("");
        setExportCompletionPercentage(0);
        showErrorModal(
          t("Export Error"),
          t(
            "An error occurred while export your report. Please try again later."
          ),
          "cil-warning"
        );
      }
    } catch (e) {
      /* Nothing to do */
    }
  }, [authContext, currentReport, exportId, onClose, t]);

  useEffect(() => {
    if (exportId) {
      checkReportPercentage();
      setIsExporting(true);
    } else {
      setIsExporting(false);
    }
  }, [checkReportPercentage, exportId]);

  const isReportChecked = (reportName) => {
    return checked.includes(reportName);
  };

  const isAllChecked = () => {
    return checked.length === reportPages.length;
  };

  const toggleCheckbox = (e, reportName) => {
    if (e.target.checked) {
      setChecked([...checked, reportName]);
    } else {
      setChecked(checked.filter((r) => r !== reportName));
    }
  };

  const toggleAllPages = (e) => {
    if (e.target.checked) {
      setChecked(reportPages.map((r) => r.name));
    } else {
      setChecked([]);
    }
  };

  function showErrorModal(title, message, iconClass) {
    setModalError((prev) => {
      return {
        ...prev,
        isVisible: true,
        messageText: message,
        titleText: title,
        alertIconClass: iconClass,
      };
    });
  }

  function closeErrorModal() {
    setModalError((prev) => {
      return { ...prev, isVisible: false };
    });
  }

  const reportPageRows = reportPages.map((report) => (
    <div className="w-100 d-flex">
      <FormGroup check className="w-100 pl-0">
        <div className="report-entry d-flex w-100">
          <Col xs={{ size: 11 }} className="report-title pl-0 pr-0">
            <Label for={report.name.replace(/\s+/g, "-").toLowerCase()} check>
              <h4>{report.displayName}</h4>
            </Label>
          </Col>
          <Col xs={{ size: 1 }} className="report-checkbox pr-0">
            <Input
              disabled={isExporting}
              type="checkbox"
              id={report.name.replace(/\s+/g, "-").toLowerCase()}
              checked={isReportChecked(report.name)}
              onChange={(e) => toggleCheckbox(e, report.name)}
            />
          </Col>
        </div>
      </FormGroup>
    </div>
  ));

  const reportPageWrapper = (
    <Col className="d-flex flex-column col-md-6 col-xs-12 p-3 report-list overflow-auto">
      <div className="d-flex report-wrapper report-wrapper-all">
        <div className="d-flex w-100 report-header">
          <div className="pr-4 report-title">
            <h3>Pages:</h3>
          </div>
          <div className="pl-4 report-checkbox ml-auto">
            <Label for={"all-reports"} className="pr-4" check>
              <h4>All</h4>
            </Label>
            <Input
              disabled={isExporting}
              type="checkbox"
              id={"all-reports"}
              name={"select-reports"}
              value={"All"}
              checked={isAllChecked()}
              onChange={toggleAllPages}
            />
          </div>
        </div>
      </div>
      <div className="d-flex flex-column report-wrapper">{reportPageRows}</div>
    </Col>
  );

  return (
    <div className="ExportReportDialog">
      <Modal isOpen={isOpen} centered autoFocus>
        <ModalHeader className="print-modal-header pt-2">
          {reportTitle ? (
            <div className="d-flex flex-wrap align-items-end pr-2 pl-2">
              {reportTitle ? (
                <p className="w-30 mb-0 pl-3 pt-3 report-title">
                  Export: {reportTitle}
                </p>
              ) : (
                ""
              )}
              <Button onClick={onClose} close />
            </div>
          ) : (
            ""
          )}
        </ModalHeader>

        <ModalBody className="pb-0 pt-0 overflow-auto print-modal-body">
          <Form className="w-100 d-flex flex-wrap">
            <Row className="w-100">
              {reportPages.length > 1 ? reportPageWrapper : ""}

              <Col className="col-md-6 col-xs-12 pr-4 pl-4 pt-3 report-options">
                <FormGroup tag="fieldset">
                  <legend>
                    <h3>Format:</h3>
                  </legend>
                  <FormGroup check>
                    <Label check htmlFor="export-pdf">
                      <Input
                        type="radio"
                        id="export-pdf"
                        name="export-format"
                        value="1"
                        disabled={isExporting}
                        checked={fileFormat === 1}
                        onChange={(e) => setFileFormat(+e.target.value)}
                      />{" "}
                      PDF
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check htmlFor="export-ppt">
                      <Input
                        type="radio"
                        id="export-ppt"
                        name="export-format"
                        value="0"
                        disabled={isExporting}
                        checked={fileFormat === 0}
                        onChange={(e) => setFileFormat(+e.target.value)}
                      />{" "}
                      PPT
                    </Label>
                  </FormGroup>
                </FormGroup>
                <FormGroup>
                  <Button
                    className="mb-2 p-1"
                    color="primary"
                    onClick={(e) => exportReport(e)}
                    disabled={!isValid}
                  >
                    {buttonText}
                  </Button>
                </FormGroup>
                <FormGroup>
                  {isExporting ? (
                    <div>
                      <Loader></Loader>
                      <p>
                        {t("Export")} {exportCompletionPercentage}%{" "}
                        {t("complete")}
                      </p>
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
      <AlertBox
        title={modalError.titleText}
        text={modalError.messageText}
        onClose={closeErrorModal}
        isOpen={modalError.isVisible}
        alertIconClass={modalError.alertIconClass}
      />
    </div>
  );
};

ExportReportDialog.propTypes = {
  buttonText: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

ExportReportDialog.defaultProps = {
  buttonText: "Download",
};

export { ExportReportDialog };
