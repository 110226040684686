import React, { Component } from 'react';
import { Button, FormGroup, Input } from 'reactstrap';
import config from '../../config';
import { withTranslation } from 'react-i18next';
import { debounce } from 'throttle-debounce';
import { withAuthContext } from '../../Auth';
import { Link } from 'react-router-dom';
import Loader from 'react-spinners/BeatLoader';

import "../../scss/useradmin/_invite_user.scss";

class SelectUser extends Component {

    constructor(props) {
        super(props);

        this.state = {
            allUsersChecked: false,

            users: [],

            // Search 
            name: null,
            email: null,
            userDataLoaded: false,
            currentSearchController: null,
            searchCount: 0
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();
    }


    isUserSelected = (user) => {
        return this.props.selectedUsers.findIndex((u) => u.id === user.id) > -1;
    }

    selectUser = (user, checked) => {

        if (checked) {
            this.props.addUsers([user]);
        } else {
            this.props.removeUsers([user]);
        }
    }

    renderUsersList = (users) => {
        return users ? users.map((user, index) => {
            return (
                <tr key={user.id}>
                    <td className="text-left">{user.firstName} {user.lastName}</td>

                    <td className="text-left">{user.email}</td>

                    <td className="text-left">{user.jobTitle}</td>

                    <td className="text-center">
                        <input
                            className="user-checkbox"
                            key={index}
                            type="checkbox"
                            name="user"
                            checked={this.isUserSelected(user)}
                            onChange={(e) => this.selectUser(user, e.target.checked)}
                        ></input>
                    </td>
                </tr>
            );
        }) : null;
    }

    debouncedLoadUserList = debounce(500, this.loadUserList);

    handleSearch = (e) => {
        e.persist();

        this.debouncedLoadUserList(e.target.value);
    }

    async loadUserList(searchValue) {
        let fetchApiUrl = `${config.apiUrl}users/internal?search=${encodeURI(searchValue)}`;

        if (this.props.authContext.isAuthenticated) {
            const { searchCount, currentSearchController } = this.state;
            if (searchCount > 0 && currentSearchController) {

                currentSearchController.abort();

            }

            if (searchValue) {
                const controller = new AbortController();
                this.setState({ currentSearchController: controller, searchCount: this.state.searchCount+1 });
                try {

                    const response = await fetch(fetchApiUrl, {
                        signal: controller.signal,
                        method: 'get',
                        headers: new Headers({
                            'Authorization': 'Bearer ' + await this.props.authContext.getAccessToken()
                        })
                    });;


                    if (response.ok) {
                        const data = await response.json();

                        if (data) {
                            this.setState({
                                users: data.users,
                                userDataLoaded: true,
                                currentSearchController: null,
                                searchValue,
                                searchCount: this.state.searchCount-1
                            });
                        }
                    }
                    else {
                        this.setState({
                            users: [],
                            userDataLoaded: true,
                            currentSearchController: null,
                            searchValue,
                            searchCount: this.state.searchCount-1
                        });
                    }
                } catch (err) {
                    this.setState({
                        users: [],
                        userDataLoaded: true,
                        currentSearchController: null,
                        searchValue,
                        searchCount: this.state.searchCount-1
                    });
                }
                
            } else {
                this.setState({ currentSearchController: null, users: [], searchValue });
            }


        }
    }

    render() {
        const { users, searchCount, searchValue } = this.state;
        const { t, selectedUsers } = this.props;
        return (
            <div className="addUser searchUser">
                <div className="MyProfile-header">
                    <div className="card addUserCard">
                        <div className="card-header d-flex justify-content-between">
                            <span>{t('Invite Users')}</span><span>{t('Step 2 of 5')}</span>
                        </div>
                        <div className="card-body">
                            <div className="tables my-4 d-flex justify-content-between">

                                <div className="card mr-4 w-50">
                                    <div className="card-header d-flex align-items-center justify-content-between">
                                        <span>{t('Search Users')}</span>
                                    </div>
                                    <div className="card-body py-4">
                                        <FormGroup className="d-flex justify-content-end mb-0">
                                            <Input type="search" placeholder={t('Search...')} name="search" onChange={this.handleSearch} />
                                            <div className="form-icon" onClick={this.handleSearch}><i className="cil-magnifying-glass"></i></div>
                                        </FormGroup>


                                        <div className="scrollable">
                                            {searchCount > 0 ? <div className="pt-2 text-center"><Loader color="#2F353A" /></div> :
                                                users.length === 0 && !!searchValue ?
                                                    <div className="pt-2 text-center">No matching users found.</div>
                                                    : <table className="table">
                                                        <tbody>
                                                            {this.renderUsersList(users)}
                                                        </tbody>
                                                    </table>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="card w-50 py-4">
                                    <div className="card-header">
                                        <span>{t('Users Added')}</span>
                                    </div>
                                    <div className="scrollable">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col">{t('Name')}</th>
                                                    <th scope="col">{t('Email')}</th>
                                                    <th scope="col">{t('Job Title')}</th>
                                                    <th scope="col">{t('Action')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {selectedUsers.map((user) => {
                                                    return (
                                                        <tr>
                                                            <td>{user.firstName} {user.lastName}</td>
                                                            <td>{user.email}</td>
                                                            <td>{user.jobTitle}</td>
                                                            <td> <button className="btn-link btn-sm trash-btn" onClick={() => this.props.removeUsers([user])}><i className="cil-trash"></i></button> </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex justify-content-between">
                                <Link to="/users"><i className="cil-arrow-left mr-2"></i>{t('Back')}</Link>
                                <Button tag={Link} color="primary" to="/users/add/assurant/permissions" disabled={selectedUsers.length > 0 ? false : true}>{t('Continue')}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(withAuthContext(SelectUser));
