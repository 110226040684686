import React from "react";
import "../../scss/components/_profile_menu.scss";
import config from "../../config";
import { useTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import classNames from "classnames";
import { Nav, NavItem, NavLink, Input } from "reactstrap";
import {
  withAuthContext,
  UserPermissionGroupNames,
  UserGroups,
} from "../../Auth";
import { useOktaAuth } from "@okta/okta-react";

function SidebarAdminMenu(props) {
  const { t, i18n } = useTranslation();
  const { location, isOpen, toggle, authContext, excludeLocations } = props;

  const { authService } = useOktaAuth();
  const logout = () => {
    authService.logout("/");
    toggle();
  };

  let isActivate = excludeLocations.some(function (loc) {
    return location.pathname.startsWith(loc);
  });

  return !(excludeLocations.includes(location.pathname) || isActivate) ? (
    <div>
      

     
        
        
            {authContext.isUserInAnyGroups(
              UserPermissionGroupNames.AuthenticatedUser
            ) ? (
              <NavItem>
                <NavLink tag={Link} to={"/user-profile"} onClick={toggle}>
                  {t("Profile")}
                </NavLink>
              </NavItem>
            ) : (
              ""
            )}

            {authContext.isUserInAnyGroups(
              UserPermissionGroupNames.ManageUsersAndClients
            ) ? (
              <NavItem>
                <NavLink tag={Link} to={"/users"} onClick={toggle}>
                  {t("Users & Permissions")}
                </NavLink>
              </NavItem>
            ) : (
              ""
            )}

            {authContext.isUserInAnyGroups(
              UserPermissionGroupNames.ViewClientPositions
            ) ? (
              <NavItem>
                <NavLink tag={Link} to={"/clientpositions"} onClick={toggle}>
                  {t("Client Info")}
                </NavLink>
              </NavItem>
            ) : (
              ""
            )}

            {authContext.isUserInAnyGroups(
              UserPermissionGroupNames.ManageClientPositions
            ) ? (
              <NavItem>
                <NavLink tag={Link} to={"/data-approvals"} onClick={toggle}>
                  {t("Data Approvals")}
                </NavLink>
              </NavItem>
            ) : (
              ""
            )}

            {authContext.isUserInAnyGroups([
              UserGroups.InternalReinsuranceTeam.oktaName,
            ]) ? (
              <NavItem>
                <NavLink tag={Link} to={"/reportsConfig"} onClick={toggle}>
                  {t("Config Reports")}
                </NavLink>
              </NavItem>
            ) : (
              ""
            )}      
          
      
    </div>
  ) : (
    ""
  );
}

export default withAuthContext(withRouter(SidebarAdminMenu));
