import React, { createContext, Component, useContext, createRef } from "react";
import { withAuthContext } from "./Auth";
import config from "./config";
const Reports = createContext({
  reports: {},
});

export default Reports.Consumer;

export const useReports = () => useContext(Reports);

export const withReports = (Component) => {
  return (props) => (
    <Reports.Consumer>
      {(value) => <Component {...props} reports={value} />}
    </Reports.Consumer>
  );
};

class ReportsController extends Component {
  constructor() {
    super();
    this.state = {
      reports: {},
      reportsInitialized: false,
    };

    this.lastTimeGetReports = createRef();
    this.isGettingReports = createRef();
  }

  componentDidUpdate() {
    this.getReports();
  }

  parseReports(reports) {
    const flatArrayToObject = (obj, array, data) => {
      if (array.length > 1) {
        var name = array[0];
        if (!obj[name]) {
          obj[name] = {};
        }
        array.splice(0, 1);
        flatArrayToObject(obj[name], array, data);
      } else {
        obj[array[0]] = {
          ...data,
          title: array[0],
        };
      }
    };

    let reportsArray = reports.map((report) => report.title.split("/"));
    let result = {};
    for (let i in reportsArray) {
      let report = reportsArray[i];
      let reportData = reports[i];
      flatArrayToObject(result, report, reportData);
    }

    return result;
  }

  async getReports() {
    const isAuthenticated = this.props.authContext.isAuthenticated;
    const tenMinutesAgo = new Date().setMinutes(new Date().getMinutes() - 10);
    if (
      isAuthenticated &&
      !this.isGettingReports.current &&
      (!this.lastTimeGetReports?.current ||
        this.lastTimeGetReports.current < tenMinutesAgo)
    ) {
      this.isGettingReports.current = true;
      let response = await fetch(`${config.apiUrl}reportsConfig/list`, {
        method: "get",
        headers: new Headers({
          Authorization:
            "Bearer " + (await this.props.authContext.getAccessToken()),
        }),
      });
      if (response.ok) {
        this.lastTimeGetReports.current = new Date();
        let reports = await response.json();
        this.rawReports = reports.map((r) => {
          let result = {};

          result.id = r.id;
          result.title = r.reportDisplayName;
          result.target = "/report/" + r.reportSlug;
          if (r.reportSlug === 'internal_uat') {
            // TODO: This should be part of the report/ config in the Hub
            result.target = `/report/${r.reportSlug}/nav`;
          }
          result.groups = r.oktaGroupNames;
          return result;
        });
        let allReports = this.parseReports(this.rawReports);
        this.filterAvailableReports(allReports);
      }
      this.isGettingReports.current = false;
    }
  }

  filterAvailableReports(reports) {
    const flterReportsTree = (report) => {
      let keys = Object.keys(report);
      for (let key of keys) {
        let reportChild = report[key];
        if (Object.keys(reportChild).includes("id")) {
          if (!this.props.authContext.isUserInAnyGroups(reportChild.groups)) {
            delete report[key];
          }
        } else {
          let mustDeleteBranch = flterReportsTree(reportChild);
          if (mustDeleteBranch) {
            delete report[key];
          }
        }
      }
      if (Object.keys(report).length === 0) {
        return true;
      }
    };

    flterReportsTree(reports);
    this.setState((prev) => ({
      ...prev,
      reports,
    }));
  }

  render() {
    return (
      <Reports.Provider value={this.state.reports}>
        {this.props.children}
      </Reports.Provider>
    );
  }
}

export const ReportsProvider = withAuthContext(ReportsController);
