import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import "../../scss/useradmin/_invite_user.scss";


class ConfirmPositionsTable extends Component {
    
    render() {
        let { t, positions } = this.props;

        let sortedPositions = positions ? positions.sort((p1, p2) => {
            if (p1.clientName > p2.clientName) return 1;
            if (p1.clientName < p2.clientName) return -1;

            if (p1.seriesNumber > p2.seriesNumber) return 1;
            if (p1.seriesNumber > p2.seriesNumber) return -1;

            return 0;
        }) : [];
        let lastClientName = "";

        return (

            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">{t('Name')}</th>
                        <th scope="col">{t('Positions')}</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedPositions.map((position) =>
                        {
                        const row = <tr key={position.id}>
                            <td>{position.clientName === lastClientName ? "" : position.clientName}</td>
                            <td>{position.seriesNumber}</td>
                        </tr>
                        lastClientName = position.clientName;
                        return row;
                        }
                    )
                    }
                </tbody>
            </table>

        );
    }
}

export default withTranslation()(ConfirmPositionsTable);
