import React from "react";
import "../../scss/components/_profile_menu.scss";
import config from "../../config";
import { useTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import classNames from "classnames";
import { Nav, NavItem, NavLink, Input } from "reactstrap";
import {
  withAuthContext,
  UserPermissionGroupNames,
  UserGroups,
} from "../../Auth";
import { useOktaAuth } from "@okta/okta-react";

function ProfileMenu(props) {
  const { t, i18n } = useTranslation();
  const { location, isOpen, toggle, authContext, excludeLocations } = props;

  const { oktaAuth } = useOktaAuth();
  const logout = () => {
    oktaAuth.signOut("/");
    sessionStorage.removeItem("isFromiFrame");
    sessionStorage.removeItem("GTM");
    toggle();
  };

  let currentLanguage = i18n.language;
  if (currentLanguage && currentLanguage.length > 2) {
    currentLanguage = currentLanguage.substring(0, 2);
  }

  const supportedLanguages = config.languages;
  const showLanguageSelect =
    supportedLanguages && supportedLanguages.length > 1;

  const setLanguage = (e) => {
    localStorage.setItem("i18nextLng", e.currentTarget.value);
    window.location.reload(false);
  };

  let isActivate = excludeLocations.some(function (loc) {
    return location.pathname.startsWith(loc);
  });

  return !(excludeLocations.includes(location.pathname) || isActivate) ? (
    <div>
      <div
        className={classNames("profile-menu-mask", { "is-open": isOpen })}
        onClick={toggle}
      ></div>

      <div
        className={classNames("profile-menu-container", "shadow", {
          "is-open": isOpen,
        })}
        onClick={toggle}
      >
        <div className="profile-menu-header">
          <button
            className="close mr-2 clear"
            aria-label="Close"
            onClick={toggle}
          >
            <i className="cil-x"></i>
          </button>
        </div>
        <div className="profile-menu clearfix">
          <Nav vertical className="list-unstyled pb-3">
            {authContext.isUserInAnyGroups(
              UserPermissionGroupNames.AuthenticatedUser
            ) ? (
              <NavItem>
                <NavLink tag={Link} to={"/user-profile"} onClick={toggle}>
                  {t("Profile")}
                </NavLink>
              </NavItem>
            ) : (
              ""
            )}

            {authContext.isUserInAnyGroups(
              UserPermissionGroupNames.ManageUsersAndClients
            ) ? (
              <NavItem>
                <NavLink tag={Link} to={"/users"} onClick={toggle}>
                  {t("Users & Permissions")}
                </NavLink>
              </NavItem>
            ) : (
              ""
            )}

            {authContext.isUserInAnyGroups(
              UserPermissionGroupNames.ViewClientPositions
            ) ? (
              <NavItem>
                <NavLink tag={Link} to={"/clientpositions"} onClick={toggle}>
                  {t("Client Info")}
                </NavLink>
              </NavItem>
            ) : (
              ""
            )}

            {authContext.isUserInAnyGroups(
              UserPermissionGroupNames.ManageClientPositions
            ) ? (
              <NavItem>
                <NavLink tag={Link} to={"/data-approvals"} onClick={toggle}>
                  {t("Data Approvals")}
                </NavLink>
              </NavItem>
            ) : (
              ""
            )}

            {authContext.isUserInAnyGroups(
              UserPermissionGroupNames.AuthenticatedUser
            ) ? (
              <NavItem>
                <NavLink tag={Link} to={"/support"} onClick={toggle}>
                  {t("Support & Tutorials")}
                </NavLink>
              </NavItem>
            ) : (
              ""
            )}
            {authContext.isUserInAnyGroups([
              UserGroups.InternalReinsuranceTeam.oktaName,
            ]) ? (
              <NavItem>
                <NavLink tag={Link} to={"/reportsConfig"} onClick={toggle}>
                  {t("Config Reports")}
                </NavLink>
              </NavItem>
            ) : (
              ""
            )}
            {showLanguageSelect ? (
              <NavItem>
                <Input
                  type="select"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  defaultValue={currentLanguage}
                  onChange={setLanguage}
                >
                  {supportedLanguages.map((l) => (
                    <option key={l.value} value={l.value}>
                      {l.text}
                    </option>
                  ))}
                </Input>
              </NavItem>
            ) : null}

            <NavItem>
              <NavLink tag={Link} to={"/"} onClick={logout}>
                <i className="cil-account-logout mr-2"></i>
                {t("Logout")}
              </NavLink>
            </NavItem>
          </Nav>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
}

export default withAuthContext(withRouter(ProfileMenu));
