import React from "react";
import "../../scss/components/general/_loader.scss";
import spinner from "../../images/AutoHubSpinnerLogo.gif";

function PageLoader() {
  return (
    <div className="inner-popup-spinner">
      <div className="spinclass">
        <div className="spincenter">
          <img src={spinner} alt="Spinner"></img>
        </div>
      </div>
    </div>
  );
}

export default PageLoader;
